<template>
  <div>
    <click-to-edit
      v-model="scope.row.scale.point_employee"
      placeholder="Выберите результат"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.point_employee')"
      style="width: 100%"
      type="select"
      select-filterable
      :select-options="[
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
      ]"
      select-option-id="id"
      select-option-name="name"
      select-cast-value-to-int
      @input="saveElementPropChange(scope.row, 'scale', {point_employee:$event})"
    ></click-to-edit>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-point-employee-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
